export const content = {
  menuItems: {
    header: [
      {
        menu: "Home",
        link: "/",
      },
      {
        menu: "About",
        link: "/about",
      },
      {
        menu: "FAQ",
        link: "/faq",
      },
      {
        menu: "Support",
        link: "/support",
      },
    ],
  },
  footerItems: {
    footer: [
      {
        menu: "About",
        link: "/about",
      },
      {
        menu: "FAQ",
        link: "/faq",
      },
      {
        menu: "Support",
        link: "/support",
      },
    ],
  },
  Policy: [
    {
      menu: "Privacy policy",
      link: "/privacy-policy",
    },
    {
      menu: "Terms & conditions",
      link: "/Terms-&-conditions",
    },
  ],
  FooterSvg: [
    // Facebook
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
        >
          <path
            fill="white"
            d="M17 1H3c-1.1 0-2 .9-2 2v14c0 1.101.9 2 2 2h7v-7H8V9.525h2v-2.05c0-2.164 1.212-3.684 3.766-3.684l1.803.002v2.605h-1.197c-.994 0-1.372.746-1.372 1.438v1.69h2.568L15 12h-2v7h4c1.1 0 2-.899 2-2V3c0-1.1-.9-2-2-2"
          />
        </svg>
      ),
      link: "https://www.facebook.com/profile.php?id=61557654092770",
    },
    // Twitter
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="white"
            d="M22.46 6c-.77.35-1.6.58-2.46.69c.88-.53 1.56-1.37 1.88-2.38c-.83.5-1.75.85-2.72 1.05C18.37 4.5 17.26 4 16 4c-2.35 0-4.27 1.92-4.27 4.29c0 .34.04.67.11.98C8.28 9.09 5.11 7.38 3 4.79c-.37.63-.58 1.37-.58 2.15c0 1.49.75 2.81 1.91 3.56c-.71 0-1.37-.2-1.95-.5v.03c0 2.08 1.48 3.82 3.44 4.21a4.22 4.22 0 0 1-1.93.07a4.28 4.28 0 0 0 4 2.98a8.521 8.521 0 0 1-5.33 1.84c-.34 0-.68-.02-1.02-.06C3.44 20.29 5.7 21 8.12 21C16 21 20.33 14.46 20.33 8.79c0-.19 0-.37-.01-.56c.84-.6 1.56-1.36 2.14-2.23"
          />
        </svg>
      ),
      link: "https://x.com/pawplaylove",
    },
    // Instagram
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="white"
            d="M7.8 2h8.4C19.4 2 22 4.6 22 7.8v8.4a5.8 5.8 0 0 1-5.8 5.8H7.8C4.6 22 2 19.4 2 16.2V7.8A5.8 5.8 0 0 1 7.8 2m-.2 2A3.6 3.6 0 0 0 4 7.6v8.8C4 18.39 5.61 20 7.6 20h8.8a3.6 3.6 0 0 0 3.6-3.6V7.6C20 5.61 18.39 4 16.4 4zm9.65 1.5a1.25 1.25 0 0 1 1.25 1.25A1.25 1.25 0 0 1 17.25 8A1.25 1.25 0 0 1 16 6.75a1.25 1.25 0 0 1 1.25-1.25M12 7a5 5 0 0 1 5 5a5 5 0 0 1-5 5a5 5 0 0 1-5-5a5 5 0 0 1 5-5m0 2a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3"
          />
        </svg>
      ),
      link: "https://www.instagram.com/pawplaylove/?next=%2Fpawplaylove%2F",
    },
  ],
  appStore: [
    {
      imgUrl: "/assets/hero-section/play-store.svg",
      link: "https://play.google.com/store/apps/details?id=com.pawplaylove",
    },
    {
      imgUrl: "/assets/hero-section/app-store.svg",
      link: "https://www.apple.com/app-store/",
    },
  ],
  whyUs: [
    {
      title: "Tailored Matching Algorithm:",
      description:
        "Our advanced matching algorithm considers the unique personalities and preferences of your pets, ensuring every match is a perfect fit.",
    },
    {
      title: "Verified Pet Profiles:",
      description:
        "Rest easy knowing that all pet profiles are verified for authenticity. Your pet's safety and well-being are our top priorities.",
    },
    {
      title: "User-Friendly Interface:",
      description:
        "Experience hassle-free pet matchmaking with our intuitive and user-friendly interface. From swiping to messaging, we've designed it with you and your pet in mind.",
    },
    {
      title: "Mobile Flexibility:",
      description:
        "Take your pet's social life on the go with our mobile app. Connect, chat, and schedule playdates anytime, anywhere.",
    },
  ],
  PetPortalSection: [
    {
      imgUrl: "/assets/pet-portal-1.png",
      content:
        "At Paw Play Love, we understand the profound bond between pet owners and their furry companions. Inspired by the undeniable joy and companionship that dogs and cats bring into our lives, we felt compelled to create a platform where these cherished relationships could flourish even further.",
    },
    {
      imgUrl: "/assets/pet-portal-2.png",
      content:
        "Our dating app isn't just about finding romantic connections; it's about fostering meaningful friendships and companionships through the common love for our four-legged friends.",
    },
    {
      imgUrl: "/assets/pet-portal-3.png",
      content:
        "We believe that dogs and cats, just like humans, thrive on companionship and social interactions. Yet, we noticed a gap in the market for a platform specifically tailored to help pet owners connect with others who share their love for animals. Thus, Paw Play Love was born",
    },
  ],
  cardsSection: [
    {
      imgUrl: "/assets/hero-section/profile-img-1.svg",
      name: "Sasha Loren",
      prof: "User",
      starImg: "/assets/hero-section/star-image.svg",
      content:
        "Amazing app! My puppy made a best friend in no time thanks to Paw Play Love. Highly recommend for pet owners seeking companionship! 🐾❤️",
    },
    {
      imgUrl: "/assets/hero-section/profile-img-2.svg",
      name: "Matt Lewis",
      prof: "User",
      starImg: "/assets/hero-section/star-image.svg",
      content:
        "My dogs made furry friends quickly. PPL simplifies connecting with other pet owners and making meet ups fun. Super easy to use, I definitely recommend.",
    },
    {
      imgUrl: "/assets/hero-section/profile-img-3.svg",
      name: "Lisa Smith",
      prof: "User",
      starImg: "/assets/hero-section/star-image.svg",
      content:
        "My cats found feline friends in no time. It’s been hard trying to set dates up with my friends, this has made the process so much more interesting!",
    },
    {
      imgUrl: "/assets/hero-section/profile-img-4.svg",
      name: "Tim Martin",
      prof: "User",
      starImg: "/assets/hero-section/star-image.svg",
      content:
        "As a dog owner dealing with anxiety issues, Paw Play Love has been a game changer. My pup found a calming companion, easing anxiety and fostering friendship. Highly recommended.",
    },
    {
      imgUrl: "/assets/hero-section/profile-img-3.svg",
      name: "Stacy White",
      prof: "User",
      starImg: "/assets/hero-section/star-image.svg",
      content:
        "In the aftermath of losing their owner tragically, PPL became a beacon of hope. My cats found compassionate companionship, easing their grief and loneliness. Truly invaluable.",
    },
  ],
  faqs: [
    {
      question: "How does the matching process work on your pet dating app?",
      answer:
        "Our app uses a simple swipe interface, where pet owners can view profiles of other pets and their owners. If both owners swipe right, it's a match!",
    },
    {
      question: "Can I specify preferences for my pet's potential matches?",
      answer:
        "Absolutely! You can set preferences based on factors like size, age, breed, and temperament to ensure your pet finds the perfect companion.",
    },
    {
      question:
        "Are there safety measures in place for pet meetups arranged through the app?",
      answer:
        "Safety is our top priority. We provide tips for safe meetups and encourage users to meet in public places. Additionally, we offer in-app messaging for communication and verification options. ",
    },
    {
      question: "What if my pet doesn't get along with a matched pet?",
      answer:
        "Not every match is perfect, which is why we encourage gradual introductions and provide guidance on helping pets get acquainted. If it doesn't work out, simply swipe to find a better match!",
    },
    {
      question:
        "Are there features for organizing group outings or events with matched pets?",
      answer:
        "Soon! Our app will offer features for arranging group outings, playdates, and even pet-friendly events in your area. It's a great way for pets and owners to socialize and make new friends together.",
    },
  ],
  support: [
    {
      head: "Contact Support",
      desc: "Feeling a bit ruff or have a tail-wagging question? Let's sniff out a solution together! Just fill out the form below, and we'll be right on it.",
    },
    {
      head: "Tail-Wagging Support 🐾 | Paw Play Love",
      desc: "Got a question that's got your tail in a twist or need some guidance on Paw Play Love? Don't hesitate to reach out to our dedicated support team! Simply fill out the form below with any inquiries or concerns, and we'll fetch the answers you need to keep your pet dating experience paw-some!",
    },
  ],
  privacyPolicy: [
    {
      head: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudLorem ipsum dolor sit amet, consectetur adipiscing elit.",
      para: [
        {
          list: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          list: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          list: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          list: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
      ],
    },
    {
      head: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudLorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ],
  termsNCondition: [
    {
      head: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudLorem ipsum dolor sit amet, consectetur adipiscing elit.",
      para: [
        {
          list: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          list: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          list: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          list: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
      ],
    },
    {
      head: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrudLorem ipsum dolor sit amet, consectetur adipiscing elit.",
    },
  ],
};
